@import '../../../../../styles/variables.scss';

.specialOffers_template {
  flex: 1;
  background-color: white;
  height: 200px;
  border-radius: $border-radius10;
  margin: 8px;
  padding: 15px 25px 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;
  }
  &_title {
    font-weight: 400;
    font-size: 0.8rem;
    margin: 0;
  }
  &_price {
    font-size: 0.8rem;
    font-weight: 700;
  }
  &_btn {
    background: #e75c51;
    height: 50px;
    width: 100%;
    border-radius: 30px;
    border: none;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    &:hover {
      background: #ffada2;
    }
  }
}
