@import '../../styles/variables.scss';

.feedbackForm {
  width: 100%;
  margin-top: 20px;
  color: $mainColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  span {
    width: 350px;
    margin-right: 100px;
    font-size: 1rem;
  }
  &_wrapper {
    display: flex;
    width: 100%;
  }
  &_item {
    height: 60px;
    width: 100%;
    border-radius: 30px;
    border: solid 1px $mainColor;
    margin-left: 12px;
  }
  &_input {
    padding-left: 30px;
    padding-right: 20px;
    font-size: 1rem;
    color: $mainColor;
    min-width: 190px;
    &::placeholder {
      color: $mainColor;
      font-size: 1rem;
    }
    &:focus {
      outline: 3px solid $mainColor;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none; // Yeah, yeah everybody write about it
  }

  input[type='number'],
  input[type='number']:hover,
  input[type='number']:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
  &_btn {
    background-color: $mainColor;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    min-width: 150px;
    &:hover {
      background-color: $aquamarine;
      color: $mainColor;
    }
  }
  &_inputError {
    color: red;
    margin-left: 40px;
  }
  &_success {
    color: $mainColor;
  }
  &_messages {
    margin-top: 20px;
    margin-left: 60px;
  }
}

@media screen and (max-width: 1050px) {
  .feedbackForm {
    flex-direction: column;
    padding: 0px;
    &_wrapper {
      padding-top: 10px;
      flex-direction: column;
    }
    span {
      margin-right: 0;
      font-size: 1.5rem;
      width: 100%;
      text-align: center;
    }
    &_item {
      margin: 0;
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 1050px) {
  .feedbackForm {
    span {
      font-size: 1.2rem;
    }
  }
}
