@import '../../styles/variables.scss';
.priceSection {
  background-color: $aquamarine;
  padding: 20px 30px 20px 30px;
  border-radius: 10px;
  &_items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 20px;
    gap: 10px;
  }

  &_link {
    color: black;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 900px) {
  .priceSection {
    margin-top: 20px;
    padding: 0px 30px 20px 30px;
    &_items {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 10px;
    }
  }
}
@media screen and (max-width: 520px) {
  .priceSection {
    margin: 0;
    padding: 10px;
    &_items {
      grid-template-columns: 1fr;
      padding-bottom: 10px;
    }
  }
}
