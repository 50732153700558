@import '../../../../styles/variables.scss';

.directions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  padding-top: 10px;
  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 720px) {
  .directions {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 476px) {
  .directions {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 420px) {
  .directions {
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    padding-top: 10px;
  }
}
