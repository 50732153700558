@import '../../../styles/variables.scss';
.backLink {
  background-color: $mainColor;
  color: white;
  border: none;
  height: 50px;
  width: 120px;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 10px;
  &:hover {
    color: $mainColor;
    background-color: white;
    border: solid 1px $mainColor;
  }
}
