@import '../../styles/variables.scss';
.modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.671);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &_body {
    width: 563px;
    height: 545px;
    background: #ffffff;
    border-radius: 10px;
    padding: 95px 30px 65px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 110;
  }
  &_close {
    background-color: $aquamarine;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
    &::after {
      content: '';
      width: 32px;
      height: 5px;
      position: absolute;
      top: 27px;
      left: 14px;
      background-color: $mainColor;
      transform: rotate(45deg);
      border-radius: 4px;
    }
    &::before {
      content: '';
      width: 32px;
      height: 5px;
      position: absolute;
      top: 27px;
      left: 14px;
      background-color: $mainColor;
      transform: rotate(315deg);
      border-radius: 4px;
      transition: all 0.5s;
    }
    &:hover {
      &::after {
        transition: all 0.5s;
        transform: rotate(135deg);
      }
      &::before {
        transition: all 0.5s;
        transform: rotate(45deg);
      }
    }
  }
  &_title {
    font-size: 2rem;
    color: $mainColor;
    text-align: center;
  }
  &_form {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 40px;

    &_input {
      width: 100%;
      height: 66px;
      border-radius: 30px;
      border: 1px solid $mainColor;
      margin-top: 10px;
      font-size: 1rem;
      padding-left: 40px;
      &::placeholder {
        color: $mainColor;
      }

      &:focus {
        outline: 1px solid $mainColor;
        box-shadow: none;
      }
    }
    &_submit {
      width: 100%;
      height: 66px;
      border-radius: 30px;
      background-color: $mainColor;
      color: white;
      margin-top: 10px;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      &:focus {
        outline: none;
        box-shadow: 0px 0px 12px 6px rgba(34, 60, 80, 0.29) inset;
      }
      &:hover {
        color: black;
        background: $aquamarine;
        box-shadow: 0 0 0 1px $mainColor;
      }
      &:disabled {
        background: $aquamarine;
        &:hover {
          color: white;
          background: $aquamarine;
          box-shadow: none;
        }
      }
    }
    &_inputError {
      font-size: 0.8rem;
      color: red;
    }
  }
  &_policyText {
    color: #858585;
    font-size: 0.8rem;
    margin-top: 10px;
    text-align: center;
    a {
      color: #494949;
      font-size: 0.8rem;
    }
  }
  &_info {
    font-weight: 400;
    font-size: 2rem;
    color: $mainColor;
    text-align: center;
    transition: all 1s;
  }
}

.hide {
  display: none;
}

.show {
  transition: all 1s;
  background: red;
  // height: 100%;
}

input:focus {
  box-shadow: 1px 1px 2px 0 red;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  appearance: none;
  -moz-appearance: textfield;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

@media screen and (max-width: 570px) {
  .modal {
    &_body {
      width: 100%;
      height: 100%;
    }
  }
}
