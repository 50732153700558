.priceSectionTemplate {
  background-color: white;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  &_title {
    font-size: 0.8rem;
  }

  &_price {
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: 700;
  }
}

@media screen and (max-width: 520px) {
  .priceSectionTemplate {
    flex-direction: row;
    &_title {
      width: 80%;
    }
    &_price {
      width: 17%;
      text-align: right;
    }
  }
}
