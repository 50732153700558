@import '../../../../../styles/variables.scss';

.directionTemplate {
  background: $mainColor;
  height: 200px;
  border-radius: 10px;
  padding: 20px 30px 30px 30px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &_title {
    font-size: 1.4em;
    font-weight: 400;
    margin: 0;
    text-decoration: none;
  }
  &_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s;
    :hover {
      transition: all 0.2s;
      margin-bottom: 5px;
    }
  }
}
@media screen and (max-width: 900px) {
  .directionTemplate {
    height: 140px;
    &_title {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 420px) {
  .directionTemplate {
    padding: 17px 17px 17px 17px;
    &_title {
      font-size: 0.8rem;
    }
  }
}
