@import '../../../styles/variables.scss';
.licensePage {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &_link {
    width: 150px;
    height: 50px;
    background-color: $mainColor;
  }
}
