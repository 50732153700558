@import '../../styles/variables.scss';

.footer {
  margin-top: 30px;
  color: white;
  width: 100%;
  height: 450px;
  background-color: $mainColor;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 300px 150px;

  a {
    color: white;
  }

  &_contacts {
    padding: 30px 25px 20px 50px;
    width: 100%;
    position: relative;

    &_phone {
      font-size: 2rem;
      text-decoration: none;
    }
    &_working_time {
      padding-top: 20px;
      font-size: 1rem;
    }
    &_adress {
      padding-top: 20px;
      font-size: 1rem;
    }
    &_social {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 70px;
      height: 70px;
    }
  }

  &_policy {
    padding: 30px 25px 20px 50px;
    order: 2;
    width: 100%;
    align-self: start;

    &_documents {
      font-size: 0.8rem;

      :first-child {
        margin-right: 33px;
      }
      a {
        font-size: 0.8rem;
      }
    }
    order: 3;
    padding-top: 28px;

    p {
      font-size: 0.8rem;
      margin-top: 20px;
    }
  }

  &_map {
    width: 100%;
    height: 450px;
  }
}

.yandex_map {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1070px) {
  .footer {
    grid-template-columns: 1fr;
    grid-template-rows: 250px 300px 150px;
    height: 700px;
    &_contacts {
      &_phone {
        font-size: 1.2rem;
      }
    }
    &_map {
      height: 300px;
    }
  }
}
@media screen and (max-width: 500px) {
  .footer {
    height: 680px;
    &_contacts {
      padding: 30px 25px 30px 25px;

      &_phone {
        font-size: 1rem;
      }

      p {
        font-size: 0.8rem;
      }
      &_social {
        width: 40px;
        height: 40px;
      }
    }

    &_policy {
      padding: 30px 25px 30px 25px;
      a,
      p {
        font-size: 0.5rem;
      }
    }
  }
}
