$mainColor: #19b3ae;
$aquamarine: #afe5e3;
$border-radius10: 10px;
$coral: #e75c51;
$peach: #fae9e8;
$textGrey: #858585;
$backroundGrey: #eeeeee;

:export {
  mainColor: $mainColor;
  aquamarine: $aquamarine;
  coral: $coral;
  border-radius10: $border-radius10;
  peach: $peach;
  textGrey: $textGrey;
  backroundGrey: $backroundGrey;
}
