@import '../../../../styles/variables.scss';
.instructions {
  margin-top: 40px;
  display: flex;

  &_diagram {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 50%;
    padding-right: 20px;

    &_item {
      width: 400px;
      padding: 20px 20px 20px 20px;
      background-color: #ffada2;
      color: white;
      font-size: 1rem;
      border-radius: $border-radius10;

      position: relative;
    }
  }

  &_first {
    margin-left: 70px;
    &::after {
      content: '1';
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #ffdad4;
      top: 26px;
      left: -60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      line-height: 2rem;
    }
    &::before {
      content: '';
      background-image: url('../../../../images/icons/arrow.svg');
      position: absolute;
      top: 16px;
      right: -84px;
      width: 89px;
      height: 77px;
    }
  }
  &_second {
    margin-top: 10px;
    align-self: flex-end;
    &::after {
      content: '2';
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #ffdad4;
      top: 26px;
      left: -60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      line-height: 2rem;
    }
    &::before {
      content: '';
      background-image: url('../../../../images/icons/arrow.svg');
      position: absolute;
      top: 96px;
      right: 1px;
      width: 89px;
      height: 77px;
      transform: rotate(96deg);
    }
  }
  &_third {
    margin-top: 10px;
    margin-left: 60px;
    &::after {
      content: '3';
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #ffdad4;
      top: 50px;
      left: -60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  &_slider_wrapper {
    width: 50%;
    height: 100%;
  }
  &_slick {
    &_slider {
      border-radius: 10px;
    }
    &_slide {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 1250px) {
  .instructions {
    &_diagram {
      &_item {
        padding: 15px 15px 15px 15px;
      }
    }

    &_first {
      margin: 0;
      align-self: flex-end;
      &::after {
        width: 40px;
        height: 40px;
        top: 30px;
        left: -50px;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
      &::before {
        display: none;
      }
    }
    &_second {
      align-self: flex-end;
      &::after {
        width: 40px;
        height: 40px;
        background-color: #ffdad4;
        top: 30px;
        left: -50px;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
      &::before {
        display: none;
      }
    }
    &_third {
      margin: 0;
      margin-top: 10px;
      align-self: flex-end;
      &::after {
        width: 40px;
        height: 40px;
        top: 50px;
        left: -50px;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .instructions {
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    &_diagram {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0;

      &_item {
        width: 600px;
        padding: 20px 20px 20px 30px;
      }
    }

    &_first {
      align-self: center;
      &::after {
        top: 13px;
        left: -50px;
      }
      &::before {
      }
    }
    &_second {
      align-self: center;
      &::after {
        top: 13px;
        left: -50px;
      }
      &::before {
      }
    }
    &_third {
      align-self: center;
      &::after {
        top: 25px;
        left: -50px;
      }
    }
    &_slider_wrapper {
      width: 100%;
      height: 400px;
    }
    &_slick {
      &_slide {
        width: 100%;
        height: 400px;
        border-radius: 10px;
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .instructions {
    &_diagram {
      padding-left: 50px;
      padding-right: 10px;
      &_item {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .instructions {
    &_slider_wrapper {
      height: 200px;
    }
    &_slick {
      &_slide {
        height: 200px;
      }
    }
    &_diagram {
      &_item {
        font-size: 0.8rem;
      }
    }
  }
}
