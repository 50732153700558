@import '../../styles/variables.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 30px;
  z-index: 100;
  box-shadow: 0px 4px 7px -5px rgba(34, 60, 80, 0.6);
  &_logo {
    width: 370px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    &_phone {
      display: none;
      color: $mainColor;
    }
  }
  &_menuBtn {
    display: flex;
  }

  &_menuBtn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $aquamarine;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    display: none;
    span {
      height: 5px;
      background-color: $mainColor;
      width: 30px;
      border-radius: 10%;
    }
    &::after {
      content: '';
      height: 5px;
      background-color: $mainColor;
      width: 30px;
      position: absolute;
      top: 19px;
      border-radius: 10%;
    }
    &::before {
      content: '';
      height: 5px;
      background-color: $mainColor;
      width: 30px;
      position: absolute;
      top: 37px;
      border-radius: 10%;
    }
  }

  &_burgerMenu {
    width: 100%;
    height: 100vh;
    background-color: white;
    margin-top: 90px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 10px 20px 10px;
    &_directions {
      width: 100%;
      background-color: $aquamarine;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 20px 20px 20px;
      a {
        color: black;
        text-decoration: none;
        margin-top: 20px;
      }
    }
    &_nav {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 20px 20px 20px;
      a {
        color: $mainColor;
        text-decoration: none;
        margin-top: 20px;
      }
    }
    &_phone {
      background-color: $mainColor;

      height: 50px;
      max-width: 600px;
      margin: 0 auto;
      margin-top: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px 0 20px;
      border-radius: 30px;
      box-sizing: border-box;

      a {
        text-decoration: none;
        color: white;
        font-size: 0.8rem;
      }
      &:hover {
        box-shadow: 0 0 0 1px $mainColor;
        background-color: white;
        transition: all 0.2s;

        a {
          color: $mainColor;
        }
      }
    }
  }

  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_nav {
    display: flex;
    list-style: none;
    padding-left: 10px;
    &_item {
      margin-left: 2px;
      margin-right: 2px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px 0 10px;
      border-radius: 20px;
      color: $mainColor;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      font-size: 1rem;
      transition: all 0.3s;
      text-align: center;
      &:hover {
        background-color: $mainColor;
        color: white;
      }
    }
    &_dropdown {
      &:hover .header_nav_dropdown_list {
        display: block;
      }
      &_list {
        width: 150px;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        list-style: none;
        background-color: $mainColor;
        padding: 0;
        border-radius: 20px;
      }
      &_item {
        height: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px 0 20px;
        border-radius: 20px;
        color: white;
        cursor: pointer;
        text-decoration: none;
        font-size: 0.8rem;
        &:hover {
          background-color: white;
          color: $mainColor;
          transition: all 0.2s;
          width: 150px;
          border-radius: 15px;
        }
      }
    }
  }
  &_phone {
    margin-left: 15px;
    background-color: $mainColor;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 20px;
    border-radius: 30px;
    box-sizing: border-box;
    animation: glowing 1300ms infinite;
    transition: all 0.3s ease-in-out 0s;
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 160%;
      top: -30%;

      left: 0;
      background: linear-gradient(
        to bottom,
        rgba(235, 218, 209, 0.26),
        rgba(255, 255, 255, 0.753) 50%,
        rgba(233, 232, 232, 0.295)
      );

      transform: rotateZ(35deg);
      animation: fromLeft infinite;

      animation-timing-function: 1s;
      animation-duration: 5s;
    }
    a {
      text-decoration: none;
      color: white;
      font-size: 0.8rem;
    }
    &:hover {
      box-shadow: 0 0 0 1px $mainColor;
      background-color: white;
      transition: all 0.3s;

      a {
        color: $mainColor;
      }
    }
  }
}

@keyframes fromLeft {
  0% {
    left: 0%;
  }

  20% {
    left: 120%;
  }

  100% {
    left: 120%;
  }
}
@media screen and (max-width: 1300px) {
  .header {
    &_wrapper {
      display: none;
    }
    &_menuBtn {
      display: flex;
    }
    &_nav {
      &_item {
        font-size: 0.8rem;
        height: 50px;
        padding: 0 10px;

        &:hover {
          background-color: $mainColor;
          color: white;
        }
      }
      &_dropdown {
        &_item {
          height: 50px;

          padding: 0 10px 0 10px;

          font-size: 0.8em;
        }
      }
    }
    &_phone {
      margin-left: 10px;
      background-color: $mainColor;
      height: 50px;
      padding: 0 10px 0 10px;
      position: relative;
    }
  }
}

@media screen and (max-width: 1040px) {
  .header {
    padding: 0 10px 0 10px;
    &_logo {
      width: 220px;
      display: block;
      &_phone {
        display: block;
      }
    }
    svg {
      width: 220px;
    }
  }
}
