@import '../../../styles/variables.scss';
.pageNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 120px;
  height: 50vh;
  &_text {
    color: $mainColor;
    margin-top: 20px;
  }
  &_link {
    width: 150px;
    height: 50px;
    border-radius: 10px;
    background-color: $mainColor;
    color: white;
    margin-top: 20px;
    border: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: $aquamarine;
      color: $mainColor;
    }
  }
}
