@import '../../styles/variables.scss';

.default {
  margin-top: 20px;
  border-radius: 10px;

  &_title {
    font-size: 2rem;
    line-height: 2rem;
    margin: 0px 0 14px 30px;
    padding-top: 20px;
    font-weight: 400;
  }

  a {
    color: black;
  }
}
@media screen and (max-width: 600px) {
  .default {
    margin-top: 10px;
    border-radius: 10px;

    &_title {
      font-size: 1.2rem;
      line-height: 1rem;
      margin: 0px 0 10px 20px;
      padding-top: 20px;
      font-weight: 400;
    }
  }
}
