* {
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0;
  scroll-behavior: smooth;
  font-size: 20px;
}
.container {
  max-width: 1366px;
  width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 1230px) {
  .container {
    width: 1000px;
  }
}

@media screen and (max-width: 1030px) {
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 830px) {
  .container {
    padding: 0 15px 0 15px;
    margin: 0 auto;
  }
}
