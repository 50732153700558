@import '../../../../styles/variables.scss';
.promo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 117px;

  &_slider {
    height: 430px;
    width: 50%;
    padding: 40px 80px 30px 40px;
    background: $aquamarine;
    border-radius: $border-radius10;
    font-weight: 400;
    color: $mainColor;
    margin-right: 5px;
    h3 {
      font-size: 2rem;
      font-weight: 400;
    }
    &_dots {
      position: absolute;
      bottom: 20px;
      left: 50px;
      width: 60px;
      height: 50px;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      list-style-type: none;
      li {
        background: rgba(94, 202, 198, 0.5);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        button {
          display: none;
        }
      }
      :global(li.slick-active) {
        background: #19b3ae;
      }
    }
  }

  &_img {
    width: 50%;
    height: 430px;
    border-radius: $border-radius10;
    object-fit: cover;
  }
}
@media screen and (max-width: 720px) {
  .promo {
    flex-direction: column;
    &_slider {
      width: 100%;
      height: 300px;
      h3 {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
    &_img {
      margin-top: 10px;
      width: 100%;
      height: 300px;
    }
  }
}
@media screen and (max-width: 420px) {
  .promo {
    margin-top: 110px;
    &_slider {
      padding: 20px 40px 15px 20px;
      height: 200px;

      h3 {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
    &_img {
      height: 200px;
    }
  }
}
