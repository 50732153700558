@import '../../../../styles/variables.scss';
.reviews {
  &_slider {
    height: 260px;
    padding-left: 20px;
    padding-right: 10px;
    padding: 20px 10px 20px 20px;
  }
  &_slide {
    box-sizing: border-box;
    height: 230px;
    width: 97% !important;
    padding: 20px 20px 40px 20px;
    border-radius: 30px;
    background-color: white;
    font-size: 1rem;
    position: relative;
    height: 230px;
    box-shadow: 2px -20px 0px -3px $backroundGrey inset;

    .slick-list {
      height: 240px;
    }
    p {
      // padding-bottom: 60px;
    }
    p,
    i {
      font-size: 0.6rem;
    }

    &::after {
      content: '';
      position: absolute;
      width: 23px;
      height: 31px;
      transform: rotate(45deg);
      bottom: 5px;
      left: 40px;
      background-color: rgb(255, 255, 255);
      z-index: 10;
    }
  }
  &_next_arrow {
    background-color: $mainColor;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -50px;
    right: 40px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: $aquamarine;
    }
    &::before {
      content: '';
      background-color: white;
      position: absolute;
      top: 8px;
      left: 21px;
      width: 3px;
      height: 14px;
      transform: rotate(136deg);
    }
    &::after {
      content: '';
      background-color: white;
      position: absolute;
      top: 16px;
      left: 21px;
      width: 3px;
      height: 14px;
      transform: rotate(45deg);
    }
  }
  &_prev_arrow {
    background-color: $mainColor;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -50px;
    right: 100px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: $aquamarine;
    }
    &::before {
      content: '';
      background-color: white;
      position: absolute;
      top: 9px;
      left: 16px;
      width: 3px;
      height: 14px;
      transform: rotate(45deg);
    }
    &::after {
      content: '';
      background-color: white;
      position: absolute;
      top: 17px;
      left: 16px;
      width: 3px;
      height: 14px;
      transform: rotate(315deg);
    }
  }
}

@media screen and (max-width: 600px) {
  .reviews {
    &_slider {
      height: 285px;
    }
    &_slide {
      height: 250px;
    }
    &_next_arrow {
      top: -35px;
      right: 5px;
      display: none;
    }
    &_prev_arrow {
      top: -35px;
      right: 53px;
      display: none;
    }
  }
}
