@import '../../../../styles/variables.scss';
.specialOffers {
  width: 100%;
  background-color: #fae9e8;
  padding: 20px 30px;
  border-radius: $border-radius10;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 10px;
}

@media screen and (max-width: 900px) {
  .specialOffers {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 720px) {
  .specialOffers {
    padding-top: 0;
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 440px) {
  .specialOffers {
    padding: 10px;
    grid-template-columns: 1fr;
  }
}
