.onlineEntryTemplate {
  background: #e75c51;
  height: 200px;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &_title {
    font-size: 1.5em;
    font-weight: 400;
    transition: all 0.2s;
    margin: 0;
    &:hover {
      transition: all 0.2s;
      font-size: 1.3rem;
    }
  }
}
@media screen and (max-width: 900px) {
  .onlineEntryTemplate {
    height: 140px;
    &_title {
      font-size: 1.2em;
    }
  }
}
@media screen and (max-width: 700px) {
  .onlineEntryTemplate {
    &_title {
      font-size: 1rem;
      &:hover {
        font-size: 1.1rem;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .onlineEntryTemplate {
    &_title {
      font-size: 0.8rem;
      &:hover {
        font-size: 1rem;
      }
    }
  }
}
